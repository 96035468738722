import React from 'react';
import './App.css';

function App() {
  return (
      <>

          <div className="main">
              <header className="header">
                  <p>
                      <div className="image">
                          <img src={"/robloxianer.webp"} alt={"Robloxianer"} className={"robloxianer"}/>
                      </div>
                      Hey! Mein Name ist Robloxianer und ich bin ein deutscher Roblox-Entwickler & Spieler.<br/>
                      Allerdings spiele ich nicht nur Roblox, sondern auch Minecraft.<br/>
                      Ich bin auch sehr lange auf Discord unterwegs und habe auch einen eigenen Server.<br/>
                      <a href={"https://join.robloxianer.de"} target={"_blank"}>Hier</a> ist der Link dazu. (Deutscher Server).<br/>
                  </p>
              </header>
          </div>
      </>
  );
}

export default App;
